import { createTheme } from "@material-ui/core/styles";

/**
 * @see https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=EF6C00&secondary.color=66BB6A&secondary.text.color=ffffff&primary.text.color=ffffff
 */
const theme = createTheme({
  palette: {
    primary: {
      main: "#ef6c00",
      contrastText: "#ffffff",
      light: "#ff9d3f",
      dark: "#b53d00",
    },
    secondary: {
      main: "#66bb6a",
      contrastText: "#ffffff",
      light: "#98ee99",
      dark: "#338a3e",
    },
  },
});

export default theme;
